<template>
  <v-navigation-drawer
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    fixed
    right
    temporary
    touchless
    width="100%"
    class="notifications-bar gray-0"
  >
    <div class="fill-height general-notifications-wrapper">
      <div class="d-flex align-center justify-space-between accent py-5 px-6">
        <div class="d-flex align-center font-weight-medium text-body-lg gray-0--text">
          <IconBell width="18" class="mr-5"/>

          <span class="mr-4">General Notification</span>
        </div>
        <UiBtn icon color="gray-0" @click="close">
          <IconCancel width="18"/>
        </UiBtn>
      </div>
      <v-flex v-if="globalLoading" class="d-flex justify-center align-center">
        <v-progress-circular
          :size="100"
          color="accent"
          indeterminate
        />
      </v-flex>
      <template v-else>
        <div class="overflow-auto pl-12">
          <div v-for="(group, key) in notificationsGroup" :key="key" class="general-notifications_group py-6 pl-2 pr-12">
            <div class="d-flex align-center space-x-3 py-2">
              <component :is="group.icon" width="32" class="primary--text ml-n1"/>
              <div class="gray-120--text space-y-1">
                <div class="text-body-2 font-weight-semi-bold">{{ group.title }}</div>
                <div class="text-captions-1">{{ group.description }}</div>
              </div>
            </div>
            <v-radio-group v-model="values[key]" class="d-inline-block pt-0" :disabled="buttonLoading" hide-details>
              <UiRadio v-for="value in group.values" :label="value.label"
                       :value="value.value" :key="value.value"/>
            </v-radio-group>
          </div>
        </div>
        <div class="py-6 px-12 d-flex justify-end space-x-3">
          <UiBtn class="px-7" outlined color="gray-60" @click="close">Cancel</UiBtn>
          <UiBtn class="px-7" color="primary" @click="saveNotifications" :loading="buttonLoading">Save & Update</UiBtn>
        </div>
      </template>

    </div>

  </v-navigation-drawer>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";
import UiRadio from "@/components/UI/UiRadio";

export default {
  name: 'GeneralNotificationsSetting',
  components: {
    IconBell: () => import('@/components/icons/IconBell'),
    IconCancel: () => import('@/components/icons/IconCancel'),
    IconMessageTextSquare: () => import('@/components/icons/IconMessageTextSquare'),
    IconUploadCloud: () => import('@/components/icons/IconUploadCloud'),
    IconFolderOutline: () => import('@/components/icons/IconFolderOutline'),
    IconFileCheck: () => import('@/components/icons/IconFileCheck'),

    UiRadio,
    UiBtn,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      buttonLoading: false,
      globalLoading: true,
      notificationsGroup: {
        messages: {
          title: "Messages",
          description: "Replies and comments",
          icon: "IconMessageTextSquare",
          values: [
            {value: 'all', label: 'All new messages'},
            {value: 'direct', label: 'Direct messages, mentions & keywords'},
            {value: 'nothing', label: 'Nothing'}
          ]
        },
        file_upload: {
          title: "File uploaded",
          description: "All notifications associated with file uploads",
          icon: "IconUploadCloud",
          values: [
            {value: 'all', label: 'Any file uploaded'},
            {value: 'nothing', label: 'Nothing'}
          ]
        },
        project: {
          title: "Project",
          description: "Status change, you will get an email notification",
          icon: "IconFolderOutline",
          values: [
            {value: 'all', label: 'All project update'},
            {value: 'nothing', label: 'Nothing'}
          ]
        },
        tasks: {
          title: "Tasks",
          description: "Any updated tasks",
          icon: "IconFileCheck",
          values: [
            {value: 'direct', label: 'Direct task assigned to me, mentions & keywords'},
            {value: 'nothing', label: 'Nothing'}
          ]
        },
        digest: {
          title: "Activity digest",
          description: "Activity Digest email",
          icon: "IconFileCheck",
          values: [
            {value: 'all', label: 'Activity Digest email'},
            {value: 'nothing', label: 'Nothing'}
          ]
        },
      },
      prevValues: {
        messages: 'nothing',
        file_upload: 'nothing',
        project: 'nothing',
        tasks: 'nothing',
        digest: 'nothing'
      },
      values: null
    }
  },
  watch: {
    'value'(val) {
      if (val && !this.values) {
        this.getNotifications()
      }
      if (!val) {
        this.values = JSON.parse(JSON.stringify(this.prevValues))
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    setData(data) {
      this.prevValues = data
      this.values = JSON.parse(JSON.stringify(this.prevValues))
    },
    async getNotifications() {
      this.globalLoading = true;
      try {
        const {data} = await this.$api.notification.getGlobalNotificationsSetting();
        this.setData(data)
      } catch (error) {
        console.error(error);
      } finally {
        this.globalLoading = false;
      }
    },
    async saveNotifications() {
      this.buttonLoading = true
      try {
        const {data} = await this.$api.notification.updateGlobalNotificationsSetting(this.values);
        this.setData(data)
        this.close()
        this.$toast.open({
          message: 'Success! General notifications saved!',
          type: 'success',
          position: 'top-right'
        });
      } catch (error) {
        this.$toast.open({
          message: 'Oops! Something went wrong!',
          type: 'error',
          position: 'top-right'
        });
      } finally {
        this.buttonLoading = false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.notifications-bar {
  max-width: 546px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  z-index: 999 !important;

  .v-navigation-drawer__border {
    display: none;
  }

  .general-notifications-wrapper {
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
  }

  .general-notifications_group {
    border-bottom: 1px solid var(--v-gray-30-base);
  }
}

</style>
